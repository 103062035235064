<template>
  <div class="inventoryIndex">
    <div class="mt-2 mb-3 pbz-font body-md-bold">
      {{ $t('sideBar.inventory_movement') }}
    </div>
    <div class="position-relative">
      <div class="d-flex mb-2">
        <a-select
          style="width: 220px"
          :disabled="loadingList"
          :value="warehouse_selected || 'all'"
          @change="handleChangeWarehouse"
        >
          <a-select-option value="all">
            {{ $t('topBar.all_Warehouse') }}
          </a-select-option>
          <a-select-option
            v-for="(business, value) in warehouse_list || []"
            :key="value"
            :value="business.id"
          >
            {{ business.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="d-flex">
        <a-button icon="filter" class="mr-2" @click="onShowModalFilter">
          {{ $t('utils.filter') + ' By' }}
        </a-button>
        <div class="d-flex ml-3 mr-3">
          <a-select
            style="width: 120px"
            :disabled="loadingList || !stockMovementData.length"
            :value="sort_mode"
            @change="handleChangeSort"
          >
            <a-select-option v-for="sort in SORT_OPTIONS" :key="sort.value" :value="sort.value">
              {{ $t('order.' + sort.label) }}
            </a-select-option>
          </a-select>
        </div>
        <a-input-search
          class="col-4"
          allow-clear
          :placeholder="$t('utils.search') + ' Product Name or No SKU'"
          :value="querySearch"
          @change="handleSearch"
        />
      </div>
    </div>
    <!-- v-if="isFiltered" -->
    <div class="d-flex flex-wrap justify-content-center mb-2 mt-3 categories">
      <div
        v-for="(item, itemIdx) in activeFilter"
        :key="item + '' + itemIdx"
        class="pbz-font overline-medium mb-1 mr-1 rounded-pill list active"
      >
        {{ item }}
      </div>
      <a-button v-if="isFiltered" class="mb-1" @click="resetFilter">
        {{ $t('utils.clearFilter') }}
      </a-button>
    </div>

    <div class="table-responsive pb-2">
      <div
        class="container-fluid border rounded py-2 mx-0 mt-3"
        :style="{ minWidth: listWidth + 'px' }"
      >
        <div class="row flex-nowrap row-calc">
          <div
            v-for="tr in [
              'productName',
              'inventory',
              'firstPeriod',
              'initialStock',
              'lastPeriod',
              'lastStock',
              'baseUOM',
            ]"
            :key="tr"
            :class="[tr === 'itemTotal' ? 'col-10p' : 'col', 'text-truncate']"
          >
            {{ $t('inventory.' + tr) }}
          </div>
        </div>
      </div>

      <LoadingListTable v-if="loadingList" class="my-2" />
      <template v-else>
        <ErrorInfo v-if="errorRows" :error-type="errorRows" />
        <template v-else>
          <template v-if="stockMovementData.length">
            <List
              v-for="list in stockMovementData"
              :key="list.id"
              :data-list="list"
              :width="listWidth"
              :permission="permission"
              class="mt-2"
              @onClickCancelOrder="onClickCancelOrder"
              @onClickAccepted="onClickAccepted"
              @onOpenCollapse="onOpenCollapse"
              @onCancelRequest="onCancelRequest"
            />
          </template>
          <!-- img-src="https://ik.imagekit.io/powerbiz/img/admin-saas/Icons.svg?updatedAt=1640182139911" -->
          <EmptyInfo
            v-else
            class="py-5"
            :title="$t('order.empty_title')"
            :description="$t('order.empty_msg')"
          />
        </template>
      </template>
    </div>
    <Pagination
      v-if="!loadingList && stockMovementData.length"
      class="text-right mt-3"
      :total="total_row"
      :page-size="PAGE_SIZE"
      :total-length-per-page="stockMovementData.length"
      :default-page="currentPage"
      @changePage="changePage"
    />

    <a-modal
      :closable="false"
      :footer="null"
      :dialog-style="{ top: '15px' }"
      :visible="showModalFilter"
      @cancel="showModalFilter = false"
    >
      <FilterList
        ref="filterModal"
        :order-period="orderPeriod"
        :load-warehouse="loadWarehouse"
        :load-channel="loadChannel"
        :options-channel="options_channel"
        :business-value="businessValue"
        :channel-value="channelValue"
        :warehouse-value="warehouseValue"
        :customer-value="customerValue"
        :courier-value="courierValue"
        @close="showModalFilter = false"
        @reset="resetFilter"
        @onFocusChannel="fetchChannel"
        @onBlurChannel="blurChannel"
        @onFocusWarehouse="fetchWarehouse"
        @onBlurWarehouse="onBlurWarehouse"
      />
    </a-modal>

    <ModalConfirm
      :visible="showModalAccepted"
      :loading="loadingAccept"
      icon="check-circle"
      :title="$t('order.accept_confirm_msg')"
      :ok-label="$t('utils.yes')"
      :cancel-label="$t('utils.cancel')"
    >
      <div
        class="ant-alert ant-alert-error ant-alert-with-description ant-alert-no-icon py-2 mb-2 text-left"
      >
        <b>{{ $t('order.orderNumber') }}</b> : {{ dataAccepted.order_number }}
      </div>
    </ModalConfirm>
  </div>
</template>

<script>
import FilterList from '@/components/Inventory/StockMovement/Form/Filter/index.vue'
import List from '@/components/Inventory/StockMovement/List/index.vue'
import ModalConfirm from '@/components/Modal/ModalConfirm.vue'
import Pagination from '@/components/Pagination/index.vue'
import LoadingListTable from '@/components/Loading/ListTable'
import ErrorInfo from '@/components/ErrorInfo'
import EmptyInfo from '@/components/EmptyInfo'
import debounce from 'lodash/debounce'
import { getInventoryMovement } from '@/api/inventory'
// import ChannelsConstants from '@/constants/channels'

const setTableWidth = () => {
  const { width } = window.screen
  return width < 821 ? 1190 : width - 343
}

export default {
  components: {
    FilterList,
    List,
    ModalConfirm,
    LoadingListTable,
    EmptyInfo,
    ErrorInfo,
    Pagination,
  },
  data() {
    return {
      SORT_OPTIONS: [
        { value: 'Desc', label: 'sortByNewest' },
        { value: 'Asc', label: 'sortByOldest' },
      ],
      PAGE_SIZE: 20,
      total_row: 0,
      showModalFilter: false,
      id: [],
      warehouse_list: [],
      querySearch: '',
      errorRows: false,
      stockMovementData: [],
      loadingList: false,
      loadChannel: true,
      loadWarehouse: true,
      customerValue: '',
      courierValue: '',
      businessValue: [],
      channelValue: [],
      warehouseValue: [],
      orderPeriod: ['', ''],
      showModalCancelOrder: false,
      loadingCancelOrder: false,
      dataCancelOrder: {},
      dataAccepted: {},
      showModalAccepted: false,
      loadingAccept: false,
      controller: null,
      listWidth: setTableWidth(),
    }
  },
  computed: {
    locale() {
      return this.$store.state?.settings?.locale || 'id-ID'
    },
    channel() {
      return this.$store.state.channel
    },
    currentPage() {
      return +this.$route.query.page || 1
    },
    sort_mode() {
      return this.$route.query.sort_mode || 'DESC'
    },
    warehouse_selected() {
      return this.$route.query.warehouse_id
    },
    options_channel() {
      return (this.$store.state?.channel?.saleschannel || []).map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    isFiltered() {
      const { path, query } = this.$route
      const {
        date_from,
        date_until,
        sales_channel_id,
        warehouse_id,
        business_id_sales,
        customer_name,
        courier_name,
      } = query
      const useWarehouse = this.getUserData.restriction_base !== 'Warehouse' && warehouse_id
      return !!(
        date_from ||
        date_until ||
        (path === '/sales' && sales_channel_id) ||
        useWarehouse ||
        business_id_sales ||
        customer_name ||
        courier_name
      )
    },
    activeFilter() {
      const {
        date_from,
        date_until,
        sales_channel_id,
        warehouse_id,
        business_id_sales,
        customer_name,
        courier_name,
      } = this.$route.query // courier_id
      let date = null,
        businessId = []
      if (date_from && date_until) {
        const formatDate = dateVal =>
          new Intl.DateTimeFormat(this.locale, { dateStyle: 'medium' }).format(dateVal)
        date = formatDate(new Date(date_from)) + ' - ' + formatDate(new Date(date_until))
      }

      if (business_id_sales) {
        const options_business = (this.$store.state?.user?.businessList || [])
          .filter(f => f.business_id !== 'all')
          .map(item => ({
            id: item.business_id,
            name: item.business_name || item.name,
          }))
        businessId = options_business.length
          ? [
              ...new Set(
                options_business
                  .filter(elem => business_id_sales.split(',').find(item => elem.id === item))
                  .map(item => item.name)
                  .join('^^')
                  .split('^^'),
              ),
            ]
          : []
      }

      const channelParam =
        this.$route.path === '/sales' && sales_channel_id ? sales_channel_id.split(',') : null
      const salesChannelId = channelParam
        ? this.channel.saleschannel
            .filter(elem => channelParam.find(item => elem.id === parseInt(item)))
            .map(item => item.name)
            .join('^^')
            .split('^^')
        : []
      let warehouseId = []
      if (this.getUserData.restriction_base !== 'Warehouse') {
        if (warehouse_id) {
          warehouseId = this.warehouse_list
            .filter(elem => warehouse_id.split(',').find(item => elem.warehouse_id === item))
            .map(item => item.name)
            .join('^^')
            .split('^^')
        }
      }

      return [
        date,
        ...businessId,
        ...salesChannelId,
        ...warehouseId,
        customer_name,
        courier_name,
      ].filter(Boolean)
    },
    getUserData() {
      return this.$store.state?.user || {}
    },
    dataWarehouse() {
      return this.$store.state?.warehouse?.warehouse?.data || []
    },
    permission() {
      let key = this.$route.meta.key
      if(this.$route.meta.key.includes('-:id')) key = key.replace('-:id', '')
      return this.$store.getters['user/can'](key)
    },
    listChannels() {
      const channel = this.$store.state.business.listChannels.find(item => item.sales_channel.code === 'distributor_redeem')
      return channel
    },
  },
  watch: {
    '$route.query'() {
      this.getData()
    },
    '$route.query.workspace_id'() {
      this.options_channel = []
      this.warehouse_list = []
      this.loadChannel = true
      this.loadWarehouse = true
      this.fetchChannel()
      this.fetchWarehouse()
    },
    listChannels() {
      this.getData()
    },
  },
  mounted() {
    this.orderPeriod = this.setDefaultOrderPeriod()
    this.getData()
    this.fetchWarehouse()
  },
  beforeDestroy() {
    // cancel the request
    if (this.controller) {
      this.controller.abort()
      this.controller = null
    }
  },
  methods: {
    setDefaultOrderPeriod() {
      return [this.$moment().subtract(30, 'days'), this.$moment()]
    },
    handleChangeSort(sort_mode) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$router.push({ query: { ...this.$route.query, sort_mode } })
      }, 500)
    },
    handleChangeWarehouse(warehouse_id) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$router.push({ query: { ...this.$route.query, warehouse_id } })
      }, 500)
    },
    handleSearch(e) {
      const value = e.target.value
      this.querySearch = value
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        // const { query } = this.$route
        const valTrim = value.trim()
        this.$router.push({
          query: {
            q: valTrim.length ? valTrim : undefined,
          },
        })
      }, 500)
    },
    resetFilter(e) {
      if (e) {
        const { path, query } = this.$route
        const filterModal = this.$refs.filterModal
        filterModal && filterModal.form.resetFields()
        const U = undefined
        this.$router.push({
          query: {
            ...query,
            sort_mode: U,
            business_id_sales: U,
            date_from: U,
            date_until: U,
            // NOTES: set sales_channel_id from query params only page '/sales-channel' | path === '/sales-channel'
            sales_channel_id:
              path?.startsWith('/sales-channel') && query.sales_channel_id
                ? query.sales_channel_id
                : U,
            warehouse_id:
              query.warehouse_id && this.getUserData.restriction_base === 'Warehouse'
                ? query.warehouse_id
                : U,
            customer_name: U,
            courier_name: U,
            order_id: U,
            filter_category: U,
          },
        })
      }
      // Reset state
      this.customerValue = ''
      this.courierValue = ''
      this.businessValue = []
      this.channelValue = []
      this.warehouseValue = []
      this.orderPeriod = this.setDefaultOrderPeriod() // Reset to initial value
    },
    changeCategorieState(state) {
      const { value, title, shipping_states } = state
      const UND = undefined
      this.$router.push({
        query: {
          ...this.$route.query,
          sales_state: value === 'all' || ['SHIPPED', 'DELIVERED'].includes(value) ? UND : value,
          shipping_states: ['SHIPPED', 'DELIVERED', 'ACCEPTED'].includes(value) ? (shipping_states === 'DELIVERED' ? `${shipping_states},BUYER_ACCEPTED` : shipping_states) : UND,
          filter_category: title,
          order_id: UND,
          page: UND,
        },
      })
    },
    changePage(page) {
      this.$router.push({ query: { ...this.$route.query, page } })
    },
    onShowModalFilter() {
      // Reset form modal filter
      const filterModal = this.$refs.filterModal
      filterModal && filterModal.form.resetFields()
      this.showModalFilter = true
    },
    resetRow() {
      this.stockMovementData = []
      this.total_row = 0
    },
    getData: debounce(async function() {
      const { path, query, params } = this.$route
      const {
        business_id,
        business_id_sales,
        sales_channel_id,
        warehouse_id,
        q,
        customer_name,
        courier_name,
        periode_awal,
        periode_akhir,
      } = query // , courier_id

      this.loadingList = true
      this.errorRows = false
      this.querySearch = q || ''
      this.showModalFilter = false // Close modal if back/forward button

      this.channelValue =
        path === '/sales' && sales_channel_id ? sales_channel_id.split(',').map(v => +v) : []

      this.warehouseValue = warehouse_id ? warehouse_id.split(',') : []

      this.customerValue = customer_name || ''
      this.courierValue = courier_name || ''

      this.controller = new AbortController()

      let channelId = +params.id || undefined

      await getInventoryMovement({
        type: 'list',
        params: {
          business_id: business_id || business_id_sales,
          page: this.currentPage,
          size: this.PAGE_SIZE,
          sort: this.sort_mode,
          // channel_id: params.id,
          warehouse_id: warehouse_id === 'all' ? undefined : warehouse_id,
          channel_id: channelId,
          search: q,
          periode_awal: this.$moment(periode_awal || this.orderPeriod[0]).format('YYYY-MM-DD'),
          periode_akhir: this.$moment(periode_akhir || this.orderPeriod[1]).format('YYYY-MM-DD'),
        },
      })
      .then(({ data: resData }) => {
        if (resData) {
          const { content, total_of_elements } = resData
          this.stockMovementData = content.map((item) => {
            const nameWarehouse = this.warehouse_list.find(f => f.id === item.warehouse_id)
            return {
              ...item,
              warehouse: nameWarehouse?.name || '-',
            }
          })
          this.total_row = total_of_elements
          this.loadingList = true
        } else {
          this.resetRow()
        }
      })
      .catch(err => {
        if (err.code !== 'ERR_CANCELED') {
          this.errorRows = err.response.status
          this.resetRow()
        }
      })
      .finally(async () => {
        this.loadingList = false
      })
    }, 500),
    blurChannel() {
      if (!this.options_channel.length && !this.loadChannel) {
        this.loadChannel = true
      }
    },
    async fetchChannel() {
      if (this.loadChannel) {
        const { business_id } = this.$route.query
        await this.$store
          .dispatch('channel/GETSALESCHANNEL', { business_id })
          .then(() => {
            // console.log('fetchChannel res: ', res)
          })
          .catch(err => {
            this.$message.error(err.message)
            document.activeElement.blur()
          })
          .finally(() => {
            this.loadChannel = false
          })
      }
    },
    onBlurWarehouse() {
      // Reset loading state
      if (!this.warehouse_list.length && !this.loadWarehouse) {
        this.loadWarehouse = true
      }
    },
    async fetchWarehouse() {
      if (this.loadWarehouse) {
        const { business_id } = this.$route.query
        await this.$store
          .dispatch('warehouse/GETSALESWAREHOUSE', {
            // limit: this.PAGE_SIZE,
            // page: 1,
            business_id,
          })
          .then(({ data }) => {
            this.warehouse_list = data || []
          })
          .catch((err) => {
            this.$message.error(err.message)
            document.activeElement.blur()
          })
          .finally(() => {
            this.loadWarehouse = false
          })
      }
    },
    async onOpenCollapse(item) {
      await this.fetchWarehouse()
      this.stockMovementData.forEach(f => {
        if (f.id === item.id) {
          // Get & Set warehouse name
          const nameWarehouse = this.warehouse_list.find(f => f.id === item.warehouse_id)
          if (nameWarehouse) {
            item.warehouseName = nameWarehouse.name
            item.warehouseAddress = nameWarehouse.address
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.row-calc > .col {
  padding-left: 9px;
  padding-right: 9px;
  flex-basis: calc(100% / 6); // 100% / 8
  max-width: calc(100% / 6);
  font-size: 13px;
}

.col-10p {
  flex-basis: 10%;
  max-width: 10%;
  font-size: 13px;
}

.inventoryIndex {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;

  .categories {
    .list {
      color: #999;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 14px;
      min-height: 30px;
      background: #fff;
    }

    .list:not(:focus) {
      border-color: #999;
    }

    .active {
      background: #4d4d4d;
      color: #fff;
      border-color: #4d4d4d;
    }

    .list:hover:not(.active) {
      border-color: #4d4d4d;
      color: #4d4d4d;
    }
  }

  .active {
    background: #0559cb;
    color: #fff;
    border: 1px solid #0559cb;
  }
}
</style>

<style lang="scss">
.skeleton-total-sales ul {
  margin: 0;
}
.skeleton-total-sales li {
  height: 18px !important;
  border: 1px solid #ddd;
}
</style>
