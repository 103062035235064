<template>
  <a-form
    ref="formRef"
    novalidate
    :form="form"
    @submit.prevent="submitFilter"
  >
    <h3 class="mb-3">
      Filter
    </h3>
    <div class="mt-3">
      <label>{{ $t('inventory.period') }}</label>
      <a-form-item>
        <a-range-picker
          v-decorator="[
            'date',
            {
              initialValue: orderPeriod,
              rules: [{ required: true, message: $t('order.require_order_period') }],
            },
          ]"
          class="w-100"
          :placeholder="[$t('utils.choose') + ' ' + $t('inventory.period'), '']"
          :allow-clear="false"
          :get-calendar-container="() => $refs.formRef.$el"
          format="YYYY-MM-DD"
          @change="onChangeDate"
        >
          <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
      </a-form-item>
    </div>

    <label>{{ $t('inventory.productName') }}</label>
    <a-form-item>
      <a-select
        v-decorator="['selectProduct']"
        placeholder="Pilih Nama Produk"
        class="w-100"
      >
        <a-select-option
          v-for="(products, value) in options_products || []"
          :key="value"
        >
          {{ products.label }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <div class="mt-3">
      <label>{{ $t('inventory.changeType') }}</label>
      <a-form-item>
        <a-select
          v-decorator="['changeType']"
          placeholder="Pilih Tipe Perubahan"
          class="w-100"
        >
          <a-select-option
            v-for="(products, index) in changeTypes"
            :key="index"
            :value="products.value"
          >
            {{ products.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>

    <div class="d-flex">
      <a-button @click="resetFilter">
        {{ $t('order.cleanFilter') }}
      </a-button>
      <a-button class="ml-auto" @click="cancelModal">
        {{ $t('utils.cancel') }}
      </a-button>
      <a-button type="primary" html-type="submit" class="ml-2">
        {{ $t('utils.apply') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import isEqual from 'lodash/isEqual'
export default {
  props: [
    'businessValue',
    'orderPeriod',
    'loadWarehouse',
    'channelValue',
    'loadChannel',
    'optionsChannel',
    'warehouseValue',
    'customerValue',
    'courierValue',
  ],
  emits: ['onFocusChannel', 'onBlurChannel', 'onFocusWarehouse', 'onBlurWarehouse'],
  data() {
    return {
      form: this.$form.createForm(this),
      // loadBusiness: true,
      value: [],
      date: [],
      warehouse: [],
      business: [],
      search: '',
      customerFiltered: [],
      dateEdit: false,
      changeTypes: [
        {
          label: 'Inbound',
          value: 'INBOUND',
        },
        {
          label: 'Cancel Inbound',
          value: 'INBOUND CANCEL',
        },
        {
          label: 'Purchase Return',
          value: 'PURCHASE_RETURN',
        },
        {
          label: 'Cancel Order',
          value: 'CANCEL_ORDER',
        },
        {
          label: 'Sales Return',
          value: 'SALES_RETURN',
        },
        {
          label: 'Outbound / Goods issue',
          value: 'OUTBOUND/GOODS_ISSUE',
        },
        {
          label: 'Adjust In',
          value: 'ADJUST_IN',
        },
        {
          label: 'Adjust Out',
          value: 'ADJUST_OUT',
        },
      ],
    }
  },
  computed: {
    options_business() {
      return (this.$store.state?.user?.businessList || [])
        .filter(f => f.business_id !== 'all')
        .map(item => {
          return {
            value: item.business_id, // business_id | warehouse_id
            label: item.business_name || item.name,
          }
        })
    },
    options_warehouse() {
      return (this.$store.state?.warehouse?.warehouse?.data || []).map(warehouse => ({
        value: warehouse.warehouse_id,
        label: warehouse.name,
      }))
    },
    options_category() {
      return (this.$store.state?.products?.categories || []).map(categories => ({
        value: categories.id,
        label: categories.name,
      }))
    },
    getUserData() {
      return this.$store.state?.user || {}
    },
  },
  methods: {
    disabledDate(current) {
      return current && current > this.$moment().endOf('day')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onChangeDate() {
      this.dateEdit = true
    },
    cancelModal() {
      this.$emit('close')
    },
    resetFilter() {
      this.dateEdit = false
      this.form.resetFields()
      this.$emit('reset')
    },
    submitFilter() {
      this.form.validateFields((err, value) => {
        if (!err) {
          const UND = undefined
          let orderIdBasedOnCustomerSelected = []

          this.customerFiltered.forEach(item => {
            orderIdBasedOnCustomerSelected = [
              ...orderIdBasedOnCustomerSelected,
              ...(item?.order_ids || []),
            ]
          })
          const { query } = this.$route
          let dateRange = {
            periode_awal: UND,
            periode_akhir: UND,
          }
          if (this.dateEdit) {
            dateRange = {
              periode_awal: value?.date?.length ? value.date[0].startOf('day').toISOString() : UND,
              periode_akhir: value?.date?.length ? value.date[1].endOf('day').toISOString() : UND,
            }
          }
          const parseQuery = {
            ...query,
            page: UND,
            change_type: value?.change_type,
            ...dateRange,
          }
          const isEdit = !isEqual(parseQuery, query)
          if (isEdit) {
            this.$router.push({ query: parseQuery })
          }
          // Reset state date edit by user
          if (!value?.date?.length) {
            this.dateEdit = false
          }
          this.cancelModal()
        }
      })
    },
  },
}
</script>
