<template>
  <div>
    <a-collapse
      ref="refCollapse"
      bordered
      class="pca-sales-item"
      :style="{ minWidth: width + 'px' }"
      default-active-key="1"
      @change="onCollapse"
    >
      <a-collapse-panel key="2" :show-arrow="false">
        <template slot="header">
          <div class="list-item">
            <div class="row flex-nowrap align-items-center row-calc fs-13px">
              <div class="col text-truncate">
                <div
                  v-if="dataList.title"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="dataList.title"
                >
                  <div class="productName">
                    {{ dataList.title }}
                  </div>
                  <div class="productSKU">
                    SKU: {{ dataList.sku }}
                  </div>
                </div>
              </div>
              <div class="col text-truncate">
                <div
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="dataList.warehouse"
                >
                  {{ dataList.warehouse }}
                  <div class="productSKU">
                    {{ dataList.business_title }}
                  </div>
                </div>
              </div>
              <div class="col text-truncate">
                <time
                  v-if="dataList.periode_awal"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="$moment(dataList.periode_awal).format('DD MMM YYYY')"
                >
                  {{ $moment(dataList.periode_awal).format('DD MMM YYYY') }}
                </time>
              </div>
              <div class="col text-truncate">
                <div
                  v-if="dataList.stock_awal"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="dataList.stock_awal"
                >
                  {{ dataList.stock_awal }}
                </div>
              </div>
              <div class="col text-truncate">
                <time
                  v-if="dataList.periode_akhir"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="$moment(dataList.periode_akhir).format('DD MMM YYYY')"
                >
                  {{ $moment(dataList.periode_akhir).format('DD MMM YYYY') }}
                </time>
              </div>
              <div class="col text-truncate">
                <div
                  v-if="dataList.stock_akhir"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="dataList.stock_akhir"
                >
                  {{ dataList.stock_akhir }}
                </div>
              </div>
              <div class="col text-truncate">
                <div
                  v-if="dataList.base_uom"
                  class="mw-100 d-inline-block align-middle text-truncate"
                  :title="dataList.base_uom"
                >
                  {{ dataList.base_uom }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="refRowDetail pt-4">
          <div class="row overflow-auto headerDetail">
            <div class="col text-truncate">
              <div class="mw-100 d-inline-block align-middle text-truncate">
                {{ $t('inventory.date') }}
              </div>
            </div>
            <div class="col text-truncate">
              <div class="mw-100 d-inline-block align-middle text-truncate">
                {{ $t('inventory.changeType') }}
              </div>
            </div>
            <div class="col text-truncate">
              <div class="mw-100 d-inline-block align-middle text-truncate">
                {{ $t('inventory.changeStock') }}
              </div>
            </div>
            <div class="col text-truncate">
              <div class="mw-100 d-inline-block align-middle text-truncate">
                {{ $t('inventory.lastStock') }}
              </div>
            </div>
          </div>
          <div v-for="(dataList, index) in listMovement" :key="index" class="row overflow-auto rowDetail pt-2 pb-2">
            <div class="col text-truncate">
              <div class="mw-100 d-inline-block align-middle text-truncate rowField">
                {{ $moment(dataList.movement_date).format('DD MMM, YYYY HH:mm') }}
              </div>
            </div>
            <div class="col text-truncate">
              <div class="mw-100 d-inline-block align-middle text-truncate rowField">
                {{ dataList.movement_type.replace("_", " ") }}
              </div>
            </div>
            <div class="col text-truncate">
              <div class="mw-100 d-inline-block align-middle text-truncate rowField">
                {{ dataList.movement_quantity }}
              </div>
            </div>
            <div class="col text-truncate">
              <div class="mw-100 d-inline-block align-middle text-truncate rowField">
                {{ dataList.stock }}
              </div>
            </div>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { getInventoryMovement } from '@/api/inventory'
// import PcaImage from '@/components/Image'
// import ModalPengiriman from '@/components/Sales/Detail/RiwayatPengiriman/Modal.vue'
// import ModalPerubahan from '@/components/Sales/Detail/RiwayatPerubahan/Modal.vue'
export default {
  // components: {
    // PcaImage,
    // ModalPengiriman,
    // ModalPerubahan,
  // },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    dataList: {
      type: Object,
      default: () => {},
    },
    shipping: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: 1190,
    },
    permission: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visiblePengiriman: false,
      visiblePerubahan: false,
      showed_product: [],
      isShow: false,
      isHiddenRincian: false,
      isHiddenRincianShp: false,
      textRincian: this.$t('order.moreDetailsTitle'),
      textRincianShp: this.$t('order.moreDetailsTitle'),
      data: [],
      value: [],
      listMovement: [],
      loadingDetail: false,
      controllerOrderCatalog: null,
      controllerShipping: null,
    }
  },
  methods: {
    parseNum(val) {
      return val.toLocaleString(this.$store.state?.settings?.locale || 'id-ID')
    },
    onAccepted(data) {
      this.$emit('onClickAccepted', data)
    },
    onClickCancelOrder(data) {
      this.$emit('onClickCancelOrder', data)
    },
    onCancelRequest(data) {
      this.$emit('onCancelRequest', data)
    },
    parseCustomerName(sales) {
      const { customer_name } = sales
      return customer_name ? customer_name.trim() : '-'
    },
    async onCollapse() {
      this.isShow = !this.isShow
      if (this.isShow) {
        this.loadingDetail = true
        const { warehouse_id, catalog_id, periode_awal, periode_akhir } = this.dataList

        await getInventoryMovement({
          type: 'detail',
          params: {
            warehouse_id,
            catalog_id,
            periode_awal,
            periode_akhir,
          },
        })
        .then(({ data }) => {
          this.listMovement = data
        })
        .catch(() => {}) // console.log('%cerr: ', 'color:yellow', err)
        // eslint-disable-next-line
        .finally(() => { this.controllerOrderCatalog = null })
        if (this.isShow) this.loadingDetail = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.productName {
  font-size: 14px;
  color: #007aff;
  font-weight: 600;
}
.productSKU {
  color: #777777
}
.rowField {
  font-size: 12px;
  padding: 10px;
}
.pca-sales-item:hover {
  border-color: #ff0a54;

  .ant-collapse-item {
    border-bottom-color: #ff0a54;
  }
}

.row-calc > .col {
  padding-left: 9px;
  padding-right: 9px;
  flex-basis: calc(100% / 6); // 100% / 8
  max-width: calc(100% / 6);
}

.col-10p {
  flex-basis: 10%;
  max-width: 10%;
}

.fs-10px {
  font-size: 10px !important;
}

.fs-13px {
  font-size: 13px !important;
}

.font-1 {
  font-family: Poppins; // font-face
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  white-space: initial;
}

.list-item {
  padding-left: 3px;
  margin-right: -1px;
}

.item {
  margin-bottom: 1.5rem;
  position: static;
  width: 100%;
  height: auto;
  background: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  cursor: pointer;
}
.custom_title_ship_text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  white-space: initial;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active di bawah versi 2.1.8 */ {
  opacity: 0;
}
.hr {
  border: none;
  border-top: 2px dotted #eee;
  color: #fff;
  background-color: #fff;
  height: 2px;
}

.red {
  color: red;
}

.refRowDetail {
  max-height: 75vh;
}

.headerDetail {
  background-color: #fafafb;
  padding: 10px;
}

.order-list {
  .ant-card-body {
    padding: 0 !important;
    .title {
      padding: .5rem;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      border-radius: 8px;
      color: #fff;

      &__content {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
      }
    }
    .content {
      padding: 1rem;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;

      &__title-product {
        font-size: 14px;
        line-height: 16px;
      }

      &__product-support {
        font-size: 10px;
        line-height: 12px;
      }

      &__total-prices {
        font-size: 11px;
        line-height: 13px;
      }

      &__showed-product {
        font-size: 11px;
        line-height: 26px;
      }

      &__calculation {
        &__grand-total {
          background: #eaedec;
          padding: .75rem;
        }

        &__tabs {
          background: #eaedec;
        }

        &__detail-calculation {
          background: #eaedec;
          padding: .75rem;
          border-top: 1px solid #dfdfdf;
          font-family: Work Sans;
          font-style: normal;
          font-size: 11px;
          line-height: 13px;

          .title {
            font-weight: normal;
          }
        }
      }

      &__shipping,
      &__payment {
        &__title {
          font-family: Work Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}
.summary-order {
  display: flex;
  justify-content: space-between;
  p {
    margin: unset;
  }
}
.summary-order-promotion {
  color: red
}
</style>

<style lang="scss">
.pca-sales-item.ant-collapse {
  .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.text-break-word {
  word-wrap: break-word;
}
</style>
